[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 360px;
}

.amplify-button--primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none;
}

[data-amplify-router] {
  --amplify-components-authenticator-router-border-style: none;
}

.amplify-alert--error {
  color: #fff;
  background-color: #dc3545;
  border-color: #d32535;
}

[data-amplify-form] {
  --amplify-components-authenticator-form-padding: 20px;
}

[data-amplify-theme] {
  --amplify-components-field-label-color: #666;
}

.amplify-input {
  border: solid 1px #ced4da;
}

.amplify-button--link {
  color: #007bff;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  #border-inline-start-color: transparent;
  border: solid 1px #ced4da;
}
